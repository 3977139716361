<template>
  <v-app class="bg-white">
    <!-- <app-bar-blur-auth class="d-md-block d-none mx-10" style="z-index: 99;"> </app-bar-blur-auth> -->
    <v-main class="auth-pages">
      <div class="header-auth position-relative border-radius-xl min-vh-100">
        <v-container class="py-0">
          <v-row>
            <v-col lg="4" md="7" class="d-flex flex-column mx-lg-0 mx-auto">
              <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in"
              >
                <!-- your content here -->
                <router-view></router-view>
              </fade-transition>
            </v-col>
            <v-col
              cols="7"
              class="
                d-lg-flex d-none
                h-100
                my-auto
                pe-0
                py-0
                position-absolute
                top-0
                end-0
                text-center
                justify-center
                flex-column
              "
            >
              <div
                class="position-relative h-100 d-flex flex-column justify-end"
              >
                <fade-transition
                  :duration="1000"
                  origin="center top"
                  mode="out-in"
                >
                  <v-img
                    transition="fade-transition"
                    v-if="!coverLoaded"
                    :src="cover.url"
                    :alt="cover.title"
                    class="position-absolute start-0 h-100 w-100"
                    style="z-index: 8"
                    gradient="to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 30%"
                  >
                  </v-img>
                </fade-transition>
                <v-img
                  transition="fade-transition"
                  v-if="coverLoaded"
                  :src="cover.url"
                  :alt="cover.title"
                  class="position-absolute start-0 h-100 w-100"
                  style="z-index: 7; opacity: 0.7"
                  gradient="to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 30%"
                >
                </v-img>
                <!-- <div class="position-relative">
                  <v-img
                    src="@/assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                    max-width="500"
                    class="w-100 position-relative z-index-2 mx-auto"
                  >
                  </v-img>
                </div> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import User from "@/api/user.js";
import DefaultcoverUrl from "@/assets/e72f0cec97576fa79cb875fd862667a.png";
export default {
  name: "AuthIllustrationLayout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
  },
  data() {
    return {
      coverLoaded: false,
      cover: {
        url: DefaultcoverUrl,
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>
